import React from "react"
import {Link} from "gatsby"

const navigation = [
  {
    name: 'e-art',
    href: 'http://e-art.co/',
    icon: (props) => (
      <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M21 12a9 9 0 01-9 9m9-9a9 9 0 00-9-9m9 9H3m9 9a9 9 0 01-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 019-9" />
      </svg>
    ),
  },
  {
    name: 'Twitter',
    href: 'https://twitter.com/wowgreat/',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
      </svg>
    ),
  },
  {
    name: 'Instagram',
    href: 'https://www.instagram.com/holgerlippmann/',
    icon: (props) => (
      <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
        <path
          fillRule="evenodd"
          d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
          clipRule="evenodd"
        />
      </svg>
    ),
  },
]

const year = new Date().getFullYear()

const Footer: React.FC = () => {
  return (
    <footer className="bg-white">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 md:flex md:items-center md:justify-between lg:px-8">
        <div className="flex justify-center space-x-6 md:order-2">
          {navigation.map((item) => (
            <a key={item.name} href={item.href} target="_blank" rel="noreferrer" className="text-gray-400 hover:text-black">
              <span className="sr-only">{item.name}</span>
              <item.icon className="h-6 w-6" aria-hidden="true" />
            </a>
          ))}
        </div>
        <div className="mt-8 md:mt-0 md:order-1">
          <p className="text-center text-sm text-base text-gray-400">
            &copy; {year} Holger Lippmann, <Link to="/legal-notice" className="hover:text-black">Imprint &amp; Disclaimer</Link>
            <span>, drop by <a className="hover:text-black" href="https://metadibs.io/" target="_blank" rel="noreferrer">Metadibs</a></span>
            <span>, supported by</span>
            <span className="px-2 grayscale hover:grayscale-0">
              <a href="https://www.nft-maker.io/pro" target="_blank" rel="noreferrer">
                <svg className="inline max-h-[20px]" xmlns="http://www.w3.org/2000/svg" width="140" height="20" viewBox="0 0 163 24" fill="none"><script xmlns=""/>
                  <path d="M138.139 13.992C140.554 13.992 142.058 12.7449 142.058 10.5591C142.058 8.37318 140.554 7.21018 138.139 7.21018H134.468V17.2708H136.566V13.992H138.139ZM138.125 9.00372C139.298 9.00372 139.974 9.57822 139.974 10.5731C139.974 11.5679 139.298 12.1985 138.125 12.1985H136.566V9.00372H138.125Z" fill="#00FF66"/>
                  <path d="M149.36 17.2708H151.913L149.319 13.3895C150.63 12.9551 151.554 11.9322 151.554 10.475C151.554 8.37318 150.078 7.21018 147.608 7.21018H143.716V17.2708H145.814V13.7118H147.125L149.36 17.2708ZM147.497 8.9757C148.808 8.9757 149.47 9.53618 149.47 10.475C149.47 11.3998 148.808 11.9603 147.497 11.9603H145.814V8.9757H147.497Z" fill="#00FF66"/>
                  <path d="M163 12.2265C163 9.2139 160.778 7 157.908 7C155.051 7 152.843 9.2139 152.843 12.2265C152.843 15.2391 155.051 17.481 157.908 17.481C160.778 17.481 163 15.2391 163 12.2265ZM154.996 12.2265C154.996 10.3769 156.1 8.90564 157.908 8.90564C159.729 8.90564 160.833 10.3769 160.833 12.2265C160.833 14.0761 159.729 15.5754 157.908 15.5754C156.1 15.5754 154.996 14.0761 154.996 12.2265Z" fill="#00FF66"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M114.348 7.66843L116.515 8.80443V17.2708H114.348V7.66843ZM123.383 7C126.35 7 128.646 9.2139 128.646 12.2265C128.646 15.2391 126.35 17.481 123.383 17.481C120.431 17.481 118.149 15.2391 118.149 12.2265C118.149 9.2139 120.431 7 123.383 7ZM123.383 8.90564C121.515 8.90564 120.374 10.3769 120.374 12.2265C120.374 14.0761 121.515 15.5754 123.383 15.5754C125.266 15.5754 126.407 14.0761 126.407 12.2265C126.407 10.3769 125.266 8.90564 123.383 8.90564ZM33.8133 17.2708H31.6884L26.9965 10.2508V17.3173H25V7.21018H27.3388L31.8168 13.8799V7.21018H33.8133V17.2708ZM37.8114 13.4315V17.2708H35.6437V7.21018H42.2608V9.04576H37.8114V11.596H41.5763V13.4315H37.8114ZM48.4463 17.2708V9.12984H51.6835V7.21018H43.0556V9.12984H46.2928V17.2708H48.4463ZM54.6346 12.5488V14.2863H50.285V12.5488H54.6346ZM61.0517 17.2708H62.9626L65.658 10.531V17.2708H67.6403V7.21018H65.0448L62.0785 14.6646L59.0979 7.21018H56.5024V17.2708H58.3563V10.5871L61.0517 17.2708ZM79.4265 17.2708H77.102L76.3319 15.113H72.3531L71.5687 17.2708H69.3868L73.2372 7.21018H75.6046L79.4265 17.2708ZM74.3639 9.53619L72.9378 13.4175H75.7472L74.3639 9.53619ZM86.7761 17.2708H89.5428L85.3785 12.0303L89.4287 7.21018H86.9045L83.2251 11.624V7.21018H81.0574V17.2708H83.2251V12.6609L86.7761 17.2708ZM93.3652 15.4212H98.0856V17.2708H91.1975V7.21018H97.9858V9.05978H93.3652V11.1756H97.5722V13.0112H93.3652V15.4212ZM105.64 17.2708H108.278L105.597 13.3895C106.952 12.9551 107.907 11.9322 107.907 10.475C107.907 8.37318 106.381 7.21018 103.829 7.21018H99.807V17.2708H101.975V13.7118H103.329L105.64 17.2708ZM103.715 8.9757C105.069 8.9757 105.754 9.53619 105.754 10.475C105.754 11.3998 105.069 11.9603 103.715 11.9603H101.975V8.9757H103.715ZM111.286 14.7735C112.071 14.7735 112.613 15.32 112.613 16.1046C112.613 16.8753 112.071 17.4218 111.286 17.4218C110.502 17.4218 109.946 16.8753 109.946 16.1046C109.946 15.32 110.502 14.7735 111.286 14.7735Z" fill="black"/>
                  <path fillRule="evenodd" clipRule="evenodd" d="M3.7092e-06 10.2581L6.50673e-06 0L19.7094 3.44609e-06V12L19.7094 21.871V10.2581H16.5559V13.1613L11.0372 10.2581V13.1613L5.51862 10.2581V13.1613L3.7092e-06 10.2581ZM3.7092e-06 10.2581L1.61105e-06 12L0 24L19.7094 24L19.7094 21.871H16.5559H11.0372H5.51862H3.7092e-06V10.2581Z" fill="black"/>
                </svg>
              </a>
            </span>
          </p>
        </div>
      </div>
    </footer>
  )
}

export default Footer
