import React from "react"
import BodyClassName from "react-body-classname"
import { Helmet } from "react-helmet"
import Footer from "../components/footer"
// import Orbitronfont from "../fonts/orbitron-black.woff2"

type Props = {
  
}

const Layout: React.FC<Props> = ({ children }) => {
  return (
    <BodyClassName
      className={`bg-white text-black font-mono ${
        process.env.TAILWINDCSS_DEBUG_SCREENS == "1" ? "debug-screens" : ""
      }`}
    >
      <>
        <Helmet>
          {/* <link
            rel="preload"
            as="font"
            href={Orbitronfont}
            type="font/woff2"
            crossOrigin="anonymous"
          /> */}
          <link rel="preconnect" href="https://fonts.googleapis.com" />
          <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="true" />
          <link href="https://fonts.googleapis.com/css2?family=Roboto+Mono&display=swap" rel="stylesheet" />
        </Helmet>
        <div className="position-relative w-screen h-screen">
          {children}
          <Footer />
        </div>
      </>
    </BodyClassName>
  )
}

export default Layout
